<template>
  <section class="news">
    <div class="newsgrid">
      <div class="newrelease"></div>
      <div class="newreleasecover">
        <img src="../assets/catalogue/POM51.jpg" alt="POM51" />
      </div>
      <div class="presstext">
        <h3>OUT NOW <i>(12" & digital)</i></h3>
        <h4>Lo-x3 - Shoc_orridor</h4>
        <p class="rec-info">
          <i
            >Lo-x3 unveils "Shoc_orridor”. The A-Side pays homage to to the
            golden era of 90s techno with “Pima" and "Thereon”. Stepping into
            the realm of sonic experimentation, the flipside treats us to a
            mesmerizing encounter with two distinct tracks. "CB-190301" weaves
            intricate soundscapes that challenge conventional norms and spark
            profound contemplation. In contrast, "Beth" unleashes a relentless
            baseline over broken beats, an unstoppable force ready to shake the
            very foundations of any sound system.</i
          >
        </p>
        <p>
          <a
            href="https://kudosdistribution.co.uk/lo-x3-shoc_orridor-pomelo/"
            target="_blank"
            >distributed by Kudos distribution</a
          >
        </p>
      </div>
      <!-- <div class="players"> -->
      <iframe
        style="border: 0; width: 90%; height: 120px; background-color: #fff"
        class="player1"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1897557090&color=%23ff5500&auto_play=false&hide_related=true&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
      ></iframe>

      <iframe
        style="border: 0; width: 90%; height: 120px"
        class="player2"
        src="https://bandcamp.com/EmbeddedPlayer/album=3204394108/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/"
        seamless
        ><a href="https://pomelo.bandcamp.com/album/shoc-orridor-pom51"
          >Shoc_orridor / POM51 by Lo-x3</a
        ></iframe
      >
    </div>
    <!-- </div> -->
  </section>
</template>

<script>
export default {
  name: "NewestNews",
};
</script>

<style scoped>
/* Base styles */
body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
}

/* News section */
.news {
  padding: 25px 15px;
  margin: auto;
  max-width: 1200px;
  background-color: #ffffff;
}

/* Grid layout */
.newsgrid {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  grid-template-columns: 1fr 2fr 2fr 1fr;
  gap: 20px;
  align-items: center;
}

.newrelease {
  grid-column: 2 / 3;
  text-align: left;
  height: 50px;
}

.newreleasecover {
  grid-column: 2 / 3;
  align-self: start;
}

.newreleasecover img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;
}

.newreleasecover img:hover {
  transform: scale(1.05);
}

/* Press text */
.presstext {
  grid-column: 3 / 4;
  padding: 0 20px 10px 20px;
  text-align: justify;
  line-height: 1.6;
}

.presstext h3 {
  font-size: 1.1rem;
  color: #fff;
  background-color: #ccc;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 0;
}

.presstext h4 {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #333;
}

.presstext p {
  font-size: 1rem;
  color: #666;
}
.presstext a {
  background-color: #fe6601;
  color: #fff;
  text-decoration: none;
}

/* Bandcamp player */
.player1,
.player2 {
  grid-column: 2 / span 2;
  margin: auto;
  max-width: 500px;
}

/* Responsiveness */
@media screen and (max-width: 768px) {
  .newsgrid {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
  }

  .newrelease,
  .newreleasecover,
  .presstext,
  .player2,
  .player1 {
    grid-column: 1;
  }

  .presstext {
    padding: 10px;
  }
}

@media screen and (min-width: 1800px) {
  .newreleasecover img {
    width: 100%;
    max-width: 400px;
  }
  .presstext {
    font-size: 1.2rem;
    line-height: 1.8;
  }
}
</style>

<template>
  <div class="video-section">
    <div class="video-container">
      <video
        id="background-video"
        src="../assets/videos/pomeloclip.m4v"
        muted="true"
        autoplay="true"
        loop="true"
        playsinline="true"
      ></video>
      <button id="toggle-mute-btn">
        <img src="../assets/sound_off_w.png" />
      </button>
    </div>
    <div class="overlay-video">
      <div class="text-container">
        <!-- <div class="headline">
          <img src="../assets/pomelo-bc-profilpic-edit.png" />
        </div> -->
        <!-- <div class="subheadline">years</div> -->
        <div class="audio">audio: POMLTD1 | /DL/MS/ - M5</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoComponent",
  mounted() {
    // Select the mute button, video element, and mute image when the component is mounted
    const muteButton = document.getElementById("toggle-mute-btn");
    const video = document.getElementById("background-video");
    const muteImage = muteButton.querySelector("img");

    // Function to toggle mute state
    const toggleMute = () => {
      if (video.muted === true) {
        muteImage.src = require("@/assets/sound_on_w.png");
        video.muted = false;
      } else {
        muteImage.src = require("@/assets/sound_off_w.png");
        video.muted = true;
      }
    };

    // Add click event listener to the mute button
    muteButton.addEventListener("click", toggleMute);
  },
};
</script>

<style scoped>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-y: hidden;
}
.video-section {
  margin: 0;
  position: relative;
  height: 100%;
  overflow: hidden;
}
.video-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px gray;
}
.video-container video {
  min-width: 100%;
  min-height: 100%;
  z-index: 11;
  /* filter: blur(7px);
  filter: saturate(4);
  filter: contrast(99.75);
  filter: invert(1); */
  filter: blur(1px) saturate(3) contrast(9.75) invert(1);
}
.overlay-video {
  width: 100vw;
  height: 100vh;
  /* background: rgba(63, 60, 5, 0.7); */
  background: rgba(150, 50, 5, 0.7);
  position: absolute;
  top: 0;
  z-index: 12;
}
.video-container button {
  z-index: 13;
  position: absolute;
  top: 130px;
  left: 50px;
}
.text-container {
  position: relative;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  height: 100vh;
}
.headline {
  font-family: Roboto, sans-serif;
  font-size: 11rem;
  font-weight: bold;
  color: #feed01;
  opacity: 70%;
  margin-bottom: 0;
}
.headline img {
  width: 40%;
}
.subheadline {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  color: #feed01;
  opacity: 40%;
  text-align: center;
  margin-top: -25px;
}
.text-container .audio {
  position: absolute;
  bottom: 20px;
  left: 50px;
  font-family: Roboto, sans-serif;
  font-size: 0.7rem;
  color: #ccc;
  opacity: 80%;
}
#toggle-mute-btn {
  width: 70px;
  background-color: transparent;
  border: 0px;
  margin: 40px 40px;
  position: absolute;
  top: 35px;
}
#toggle-mute-btn img {
  width: 33px;
  opacity: 40%;
}
</style>

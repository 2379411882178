<template>
  <div class="home">
    <VideoComponent /><br />
    <!-- <NewsComponent /><br />
    <SlideComponent /><br />
    <ArtistsComponent /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import VideoComponent from "@/components/VideoComponent.vue";
// import NewsComponent from "@/components/NewsComponent.vue";
// import SlideComponent from "@/components/SlideComponent.vue";
// import ArtistsComponent from "@/components/ArtistsComponent.vue";

export default {
  name: "HomeView",
  components: {
    VideoComponent,
    // NewsComponent,
    // SlideComponent,
    // ArtistsComponent,
  },
};
</script>
<style scoped>
.home {
  height: 100%;
  margin: 0 auto;
  background-color: #fff;
  /* overflow: hidden; */
}
</style>

<template>
  <div class="newsss">
    <span><h2>NEWS</h2></span>
    <button
      v-if="currentNewsIndex < newsList.length - 1"
      @click="nextNews"
      class="next-button"
    >
      Next
    </button>
    <!-- Dynamische Komponente -->
    <div class="news-content">
      <component :is="currentNewsComponent" />
    </div>
  </div>
</template>

<script>
import NewestNews from "@/components/NewestNews.vue";
import NewNews from "@/components/NewNews.vue";
import NewNews2 from "@/components/NewNews2.vue";

export default {
  name: "NewsView",
  components: {
    NewestNews,
    NewNews,
    NewNews2,
  },
  data() {
    return {
      newsList: ["NewestNews", "NewNews", "NewNews2"],
      currentNewsIndex: 0,
    };
  },
  computed: {
    currentNewsComponent() {
      return this.newsList[this.currentNewsIndex];
    },
  },
  methods: {
    nextNews() {
      if (this.currentNewsIndex < this.newsList.length - 1) {
        this.currentNewsIndex++;
      }
    },
  },
};
</script>

<style scoped>
.newsss {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  margin: 0 auto;
  padding-top: 25px;
  background-color: #fff;
  position: relative;
  margin-bottom: 60px;
  max-width: 1200px;
}
.newsss span h2 {
  text-align: left;
  color: rgb(34, 33, 33);
  font-size: 1.5rem;
  margin-left: 100px;
  position: absolute;
  top: 5px;
  text-decoration: wavy;
  white-space: nowrap;
}
button {
  all: unset;
  position: absolute;
  top: 5px;
  right: 20px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #d7d7d6;
  border-radius: 4px;
  border: 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 7px 0 0 0;
  min-height: 40px;
  min-width: 100px;
  padding: 3px 5px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
  margin-top: 15px;
  z-index: 5;
}
button:hover {
  background-color: #3c3730;
  transform: translateY(-2px);
}
button:active {
  background-color: #fff;
  box-shadow: #422800 2px 2px 0 0;
}
</style>

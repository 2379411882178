<template>
  <section class="news">
    <div class="newsgrid">
      <div class="newrelease"></div>
      <div class="newreleasecover">
        <img src="../assets/catalogue/pom02.jpg" alt="POM01" />
      </div>
      <div class="presstext">
        <h3><i>Classics on Bandcamp</i></h3>
        <h4>Horn - Schall und Rauch (1995)</h4>
        <p class="rec-info">
          <i
            >As we continue our mission to make our entire back catalogue
            digitally available on Bandcamp, today we’re excited to celebrate
            the digital release of our second release: the classic Schall +
            Rauch, originally issued in January 1995. Created by the acclaimed
            artist Richard Bartz under his Horn alias, this EP captures the raw,
            hypnotic energy of early techno and electronic experimentation. We
            are delighted to bring this record to a new generation of listeners
            with this digital release.</i
          >
        </p>
      </div>
      <iframe
        style="border: 0; width: 90%; height: 120px"
        class="player2"
        src="https://bandcamp.com/EmbeddedPlayer/album=1593881359/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/"
        seamless
        ><a href="https://pomelo.bandcamp.com/album/schall-rauch-pom02"
          >Schall + Rauch / POM02 by Horn</a
        ></iframe
      >
    </div>
  </section>
</template>

<style scoped>
/* Base styles */
body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
}

/* News section */
.news {
  padding: 25px 15px;
  margin: auto;
  max-width: 1200px;
  background-color: #ffffff;
}

/* Grid layout */
.newsgrid {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  grid-template-columns: 1fr 2fr 2fr 1fr;
  gap: 20px;
  align-items: center;
}

.newrelease {
  grid-column: 2 / 3;
  text-align: left;
  height: 50px;
}

.newreleasecover {
  grid-column: 2 / 3;
  align-self: start;
}

.newreleasecover img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;
}

.newreleasecover img:hover {
  transform: scale(1.05);
}

/* Press text */
.presstext {
  grid-column: 3 / 4;
  padding: 0 20px 10px 20px;
  text-align: justify;
  line-height: 1.6;
}

.presstext h3 {
  font-size: 1.1rem;
  color: #fff;
  background-color: #ccc;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 0;
}

.presstext h4 {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #333;
}

.presstext p {
  font-size: 1rem;
  color: #666;
}

/* Bandcamp player */
.player2 {
  grid-column: 2 / span 2;
  margin: auto;
  max-width: 500px;
}

/* Responsiveness */
@media screen and (max-width: 768px) {
  .newsgrid {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
  }

  .newrelease,
  .newreleasecover,
  .presstext,
  .player2 {
    grid-column: 1;
  }

  .presstext {
    padding: 10px;
  }
}

@media screen and (min-width: 1800px) {
  .newreleasecover img {
    width: 100%;
    max-width: 400px;
  }
  .presstext {
    font-size: 1.2rem;
    line-height: 1.8;
  }
}
</style>
